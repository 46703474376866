import React from 'react'

const Header = () => {
    return (
        <section className="header-area">
	        <div className="navbar-area">
	            <div className="container">
	                <div className="row">
	                    <div className="col-lg-12">
	                        <nav className="navbar navbar-expand-lg">
	                            <a className="navbar-brand" href="#">
	                                <img src="assets/images/logo.png" alt="Logo" />
	                            </a>

	                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarEight" aria-controls="navbarEight" aria-expanded="false" aria-label="Toggle navigation">
	                                <span className="toggler-icon"></span>
	                                <span className="toggler-icon"></span>
	                                <span className="toggler-icon"></span>
	                            </button>

	                            <div className="collapse navbar-collapse sub-menu-bar" id="navbarEight">
	                                <ul className="navbar-nav ml-auto">
	                                    <li className="nav-item active">
	                                        <a className="page-scroll" href="#home">INICIO</a>
	                                    </li>
	                                    <li className="nav-item">
	                                        <a className="page-scroll" href="#about">¿QUE SON?</a>
	                                    </li>
	                                    <li className="nav-item">
	                                        <a className="page-scroll" href="#portfolio">DUITALLES</a>
	                                    </li>
	                                    <li className="nav-item">
	                                        <a className="page-scroll" href="#pricing">PRECIOS</a>
	                                    </li>
	                                    <li className="nav-item">
	                                        <a className="page-scroll" href="#testimonial">CLIENTES</a>
	                                    </li>
	                                    <li className="nav-item">
	                                        <a className="page-scroll" href="#contact">CONTACTO</a>
	                                    </li>
	                                </ul>
	                            </div>
	                        </nav>
	                    </div>
	                </div>
	            </div>
	        </div>
	        
	        <div id="home" className="slider-area">
	            <div className="bd-example">
	                <div id="carouselOne" className="carousel slide" data-ride="carousel">
	                    <ol className="carousel-indicators">
	                        <li data-target="#carouselOne" data-slide-to="0" className="active"></li>
	                        <li data-target="#carouselOne" data-slide-to="1"></li>
	                        <li data-target="#carouselOne" data-slide-to="2"></li>
	                    </ol>

	                    <div className="carousel-inner">
	                        <div className="carousel-item bg_cover active" style={{ background: "linear-gradient(to right, #9a3030 0%, #1d5d28 100%)" }}>
	                            <div className="carousel-caption">
	                                <div className="container">
	                                    <div className="row justify-content-center">
	                                        <div className="col-xl-6 col-lg-7 col-sm-10">
	                                            <h2 className="carousel-title">¿Deseas regalar algo, pero la distancia te lo impide?</h2>
	                                        </div>
	                                    </div>
	                                </div>
	                            </div>
	                        </div>

	                        <div className="carousel-item bg_cover" style={{ background: "linear-gradient(to right, #1d5d28 0%, #48769a 100%)" }}>
	                            <div className="carousel-caption">
	                                <div className="container">
	                                    <div className="row justify-content-center">
	                                        <div className="col-xl-6 col-lg-7 col-sm-10">
	                                            <h2 className="carousel-title">¿Olvidaste tu regalo de mes o aniversario?</h2>
	                                        </div>
	                                    </div>
	                                </div>
	                            </div>
	                        </div>

	                        <div className="carousel-item bg_cover" style={{ background: "linear-gradient(to right, #48769a 0%, #9a3030 100%)" }}>
	                            <div className="carousel-caption">
	                                <div className="container">
	                                    <div className="row justify-content-center">
	                                        <div className="col-xl-6 col-lg-7 col-sm-10">
	                                            <h2 className="carousel-title">¿Quieres sorprender como nadie más lo haría?</h2>
	                                        </div>
	                                    </div>
	                                </div>
	                            </div>
	                        </div>
	                    </div>

	                    <a className="carousel-control-prev" href="#carouselOne" role="button" data-slide="prev">
	                        <i className="lni-arrow-left-circle"></i>
	                    </a>

	                    <a className="carousel-control-next" href="#carouselOne" role="button" data-slide="next">
	                        <i className="lni-arrow-right-circle"></i>
	                    </a>
	                </div>
	            </div>
	        </div>

	    </section>
    )
}

export default Header;