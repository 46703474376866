import React from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import Inicio from './Views/Inicio'

import Header from './Components/Layout/Header'
import Loader from './Components/Layout/Loader'
import Footer from './Components/Layout/Footer'

function App() {
  return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <div id="main-wrap">
                        <Loader />
                        <Header />
                        <div className="overlay-right"></div>
                        <Inicio />
                        <Footer />
                        <a href="#" className="back-to-top"><i className="lni-chevron-up"></i></a>
                    </div>
                </Route>
                <Route path="/">
                    <div id="main-wrap">
                        <Loader />
                        <Header />
                        <div className="overlay-right"></div>
                        <Inicio />
                        <Footer />
                        <a href="#" className="back-to-top"><i className="lni-chevron-up"></i></a>
                    </div>
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
