import React from 'react'

const Footer = () => {
    return (
        <footer id="footer" className="footer-area">
			<div className="footer-copyright">
	            <div className="container">
	                <div className="row align-items-center">
	                    <div className="col-lg-12">
	                        <div className="footer-logo text-center mt-10">
	                            <a className="page-scroll" href="#home"><img src="assets/images/isotipo.png" alt="Logo" /></a>
	                        </div>
	                    </div>
	                </div>
	            </div>
	        </div>
	    </footer>
    )
}

export default Footer;