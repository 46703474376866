import React, { Fragment } from 'react'

const Encuesta = () => {
    
    return (
    	<Fragment>
			<section id="about" className="about-area">
		        <div className="container">
		            <div className="row justify-content-center">
		                <div className="col-xl-6 col-lg-8">
		                    <div className="section-title text-center mt-30 pb-40">
		                        <h4 className="title wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="0.6s">¿Que es un Duitalle?</h4>
		                        <p className="text wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="1s">Básicamente, es una sorpresa digital, que puede llevar desde fotos, canciones, videos o mensajes.</p>
		                    </div>
		                </div>
		            </div>
		            
		            <div className="row contenedor_iconos">
		                <div className="col-lg-6">
		                    <div className="single-about d-sm-flex mt-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="1.2s">
		                        <div className="about-icon">
		                            <img src="assets/images/icon-1.png" alt="Icon" />
		                        </div>
		                        <div className="about-content media-body">
		                            <h4 className="about-title">Contenido</h4>
		                            <p className="text">Puede ser desde fotos, videos, frases, preguntas o canciones.</p>
		                        </div>
		                    </div>
		                </div>
		                <div className="col-lg-6">
		                    <div className="single-about d-sm-flex mt-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="1.4s">
		                        <div className="about-icon">
		                            <img src="assets/images/icon-2.png" alt="Icon" />
		                        </div>
		                        <div className="about-content media-body">
		                            <h4 className="about-title">Ver y compartir</h4>
		                            <p className="text">Tan sencillo de ver como cualquier página en internet, pero con tu dirección propia.</p>
		                        </div>
		                    </div>
		                </div>
		                <div className="col-lg-6">
		                    <div className="single-about d-sm-flex mt-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="1.6s">
		                        <div className="about-icon">
		                            <img src="assets/images/icon-3.png" alt="Icon" />
		                        </div>
		                        <div className="about-content media-body">
		                            <h4 className="about-title">Creatividad</h4>
		                            <p className="text">Las sorpresas ya están listas para ti, o bien, podemos añadir tu toque personal.</p>
		                        </div>
		                    </div>
		                </div>
		                <div className="col-lg-6">
		                    <div className="single-about d-sm-flex mt-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="1.8s">
		                        <div className="about-icon">
		                            <img src="assets/images/icon-4.png" alt="Icon" />
		                        </div>
		                        <div className="about-content media-body">
		                            <h4 className="about-title">Costo / beneficio</h4>
		                            <p className="text">Original, con tu toque personal y barato, el detalle perfecto para dar.</p>
		                        </div>
		                    </div>
		                </div>
		            </div>
		        </div>
		    </section>		   

		    <section id="portfolio" className="portfolio-area">
		        <div className="container">
		            <div className="row justify-content-center">
		                <div className="col-lg-6">
		                    <div className="section-title text-center pb-20">
		                        <h3 className="title">Duitalles</h3>
		                        <p className="text">Aquí hay algunos ejemplos de las ideas que puedes obsequiar.</p>
		                    </div>
		                </div>
		            </div>
		            <div className="row">
		                <div className="col-lg-4 col-sm-6 branding-3 planning-3">
		                    <div className="single-portfolio mt-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="0.2s">
		                        <div className="portfolio-image">
		                            <img src="assets/images/portfolio-1.JPG" alt="" />
		                            <div className="portfolio-overlay d-flex align-items-center justify-content-center">
		                                <div className="portfolio-content">
		                                    <div className="portfolio-icon">
		                                        <a className="image-popup" href="assets/images/portfolio-1.JPG"><i className="lni-zoom-in"></i></a>
		                                    </div>
		                                    <div className="portfolio-icon">
		                                        <a href="https://amor.duitalles.com" target="_blank" rel="noopener noreferrer"><i className="lni-link"></i></a>
		                                    </div>
		                                </div>
		                            </div>
		                        </div>
		                        <div className="portfolio-text">
		                            <h4 className="portfolio-title"><a href="https://amor.duitalles.com" target="_blank" rel="noopener noreferrer">Página amorosa</a></h4>
		                            <p className="text">Fotografías, videos y mensajes, todo con una canción de fondo.</p>
		                        </div>
		                    </div>
		                </div>
		                <div className="col-lg-4 col-sm-6 marketing-3 research-3">
		                    <div className="single-portfolio mt-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="0.4s">
		                        <div className="portfolio-image">
		                            <img src="assets/images/portfolio-5.JPG" alt="" />
		                            <div className="portfolio-overlay d-flex align-items-center justify-content-center">
		                                <div className="portfolio-content">
		                                    <div className="portfolio-icon">
		                                        <a className="image-popup" href="assets/images/portfolio-5.JPG"><i className="lni-zoom-in"></i></a>
		                                    </div>
		                                    <div className="portfolio-icon">
		                                        <a href="https://boda.duitalles.com" target="_blank" rel="noopener noreferrer"><i className="lni-link"></i></a>
		                                    </div>
		                                </div>
		                            </div>
		                        </div>
		                        <div className="portfolio-text">
		                            <h4 className="portfolio-title"><a href="https://boda.duitalles.com" target="_blank" rel="noopener noreferrer">Página de boda</a></h4>
		                            <p className="text">Detalles del evento, lista de invitados, mesa de regalos y mucho más.</p>
		                        </div>
		                    </div>
		                </div>
		                <div className="col-lg-4 col-sm-6 planning-3">
		                    <div className="single-portfolio mt-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="0.7s">
		                        <div className="portfolio-image">
		                            <img src="assets/images/portfolio-3.JPG" alt="" />
		                            <div className="portfolio-overlay d-flex align-items-center justify-content-center">
		                                <div className="portfolio-content">
		                                    <div className="portfolio-icon">
		                                        <a className="image-popup" href="assets/images/portfolio-3.JPG"><i className="lni-zoom-in"></i></a>
		                                    </div>
		                                    <div className="portfolio-icon">
		                                        <a href="https://cumple.duitalles.com" target="_blank" rel="noopener noreferrer"><i className="lni-link"></i></a>
		                                    </div>
		                                </div>
		                            </div>
		                        </div>
		                        <div className="portfolio-text">
		                            <h4 className="portfolio-title"><a href="https://cumple.duitalles.com" target="_blank" rel="noopener noreferrer">Tarjeta de felicitación</a></h4>
		                            <p className="text">Comparte tu página con quienes deban escribir su felicitación.</p>
		                        </div>
		                    </div>
		                </div>
		                <div className="offset-lg-2 col-lg-4 col-sm-6 branding-3 marketing-3">
		                    <div className="single-portfolio mt-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="0.4s">
		                        <div className="portfolio-image">
		                            <img src="assets/images/portfolio-2.JPG" alt="" />
		                            <div className="portfolio-overlay d-flex align-items-center justify-content-center">
		                                <div className="portfolio-content">
		                                    <div className="portfolio-icon">
		                                        <a className="image-popup" href="assets/images/portfolio-2.JPG"><i className="lni-zoom-in"></i></a>
		                                    </div>
		                                    <div className="portfolio-icon">
		                                        <a href="https://encuesta.duitalles.com" target="_blank" rel="noopener noreferrer"><i className="lni-link"></i></a>
		                                    </div>
		                                </div>
		                            </div>
		                        </div>
		                        <div className="portfolio-text">
		                            <h4 className="portfolio-title"><a href="https://encuesta.duitalles.com" target="_blank" rel="noopener noreferrer">Encuesta fake</a></h4>
		                            <p className="text">Preguntas que parecen reales, pero te llevan a una sorpresa.</p>
		                        </div>
		                    </div>
		                </div>
		                <div className="col-lg-4 col-sm-6 branding-3 marketing-3">
		                    <div className="single-portfolio mt-30 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="0.2s">
		                        <div className="portfolio-image">
		                            <img src="assets/images/portfolio-4.JPG" alt="" />
		                            <div className="portfolio-overlay d-flex align-items-center justify-content-center">
		                                <div className="portfolio-content">
		                                    <div className="portfolio-icon">
		                                        <a className="image-popup" href="assets/images/portfolio-4.JPG"><i className="lni-zoom-in"></i></a>
		                                    </div>
		                                    <div className="portfolio-icon">
		                                        <a href="https://navidad.duitalles.com" target="_blank" rel="noopener noreferrer"><i className="lni-link"></i></a>
		                                    </div>
		                                </div>
		                            </div>
		                        </div>
		                        <div className="portfolio-text">
		                            <h4 className="portfolio-title"><a href="https://navidad.duitalles.com" target="_blank" rel="noopener noreferrer">Tarjeta navideña</a></h4>
		                            <p className="text">Regala un poco de amor de manera digital en esta época.</p>
		                        </div>
		                    </div>
		                </div>
		            </div>
		        </div>
		    </section>

		    <section id="pricing" className="pricing-area">
		        <div className="container">
		            <div className="row justify-content-center">
		                <div className="col-lg-6">
		                    <div className="section-title text-center pb-20">
		                        <h3 className="title">Precios</h3>
		                        <p className="text">Estos son los precios individuales, si deseas combinar o modificar los diseños, el precio puede variar.</p>
		                    </div>
		                </div>
		            </div>
		            <div className="row justify-content-center">                
   		                <div className="col-lg-4 col-md-6 col-sm-9">
		                    <div className="pricing-style-one mt-40 wow fadeIn" data-wow-duration="1.5s" data-wow-delay="0.5s">
		                        <div className="pricing-header text-center">
		                            <h5 className="sub-title">Página de Boda</h5>
		                            <p className="month"><span className="price">$ 1,500</span></p>
		                        </div>
		                        <div className="pricing-list">
		                            <ul>
		                                <li><i className="lni-check-mark-circle"></i> 5 meses publicada</li>
		                                <li><i className="lni-check-mark-circle"></i> Lista y confirmación de invitados</li>
		                                <li><i className="lni-check-mark-circle"></i> Asignación de mesas</li>
		                                <li><i className="lni-check-mark-circle"></i> Envío por WhatsApp</li>
		                                <li><i className="lni-check-mark-circle"></i> Blog personal</li>
		                                <li><i className="lni-check-mark-circle"></i> Panel administrador</li>
		                            </ul>
		                        </div>
		                        <div className="pricing-btn rounded-buttons text-center">
		                            <button type="button" className="main-btn rounded-three" data-toggle="modal" data-target="#modal_pb">COMPRAR</button>
		                        </div>
		                    </div>
		                </div>

		                <div className="col-lg-4 col-md-6 col-sm-9">
		                    <div className="pricing-style-one mt-40 wow fadeIn" data-wow-duration="1.5s" data-wow-delay="0.5s">
		                        <div className="pricing-header text-center">
		                            <h5 className="sub-title">Página Amorosa</h5>
		                            <p className="month"><span className="price">$ 100</span></p>
		                        </div>
		                        <div className="pricing-list">
		                            <ul>
		                                <li><i className="lni-check-mark-circle"></i> 7 días publicada</li>
		                                <li><i className="lni-check-mark-circle"></i> Canción de tu elección</li>
		                                <li><i className="lni-check-mark-circle"></i> Impresión PDF</li>
		                            </ul>
		                        </div>
		                        <div className="pricing-btn rounded-buttons text-center">
		                            <button type="button" className="main-btn rounded-three" data-toggle="modal" data-target="#modal_pa">COMPRAR</button>
		                        </div>
		                    </div>
		                </div>

		                <div className="col-lg-4 col-md-6 col-sm-9">
		                    <div className="pricing-style-one mt-40 wow fadeIn" data-wow-duration="1.5s" data-wow-delay="0.8s">
		                        <div className="pricing-header text-center">
		                            <h5 className="sub-title">Tarjeta de felicitacion</h5>
		                            <p className="month"><span className="price">$ 50</span></p>
		                        </div>
		                        <div className="pricing-list">
		                            <ul>
		                                <li><i className="lni-check-mark-circle"></i> 3 días publicada</li>
		                                <li><i className="lni-check-mark-circle"></i> Canción de tu elección</li>
		                            </ul>
		                        </div>
		                        <div className="pricing-btn rounded-buttons text-center">
		                            <button type="button" className="main-btn rounded-three" data-toggle="modal" data-target="#modal_tf">COMPRAR</button>
		                        </div>
		                    </div>
		                </div>

		                <div className="col-lg-4 col-md-6 col-sm-9">
		                    <div className="pricing-style-one mt-40 wow fadeIn" data-wow-duration="1.5s" data-wow-delay="0.2s">
		                        <div className="pricing-header text-center">
		                            <h5 className="sub-title">Encuesta Fake</h5>
		                            <p className="month"><span className="price">$ 50</span></p>
		                        </div>
		                        <div className="pricing-list">
		                            <ul>
		                                <li><i className="lni-check-mark-circle"></i> 3 días publicada</li>
		                                <li><i className="lni-check-mark-circle"></i> Encuesta de tu elección </li>
		                            </ul>
		                        </div>
		                        <div className="pricing-btn rounded-buttons text-center">
		                            <button type="button" className="main-btn rounded-three" data-toggle="modal" data-target="#modal_ef">COMPRAR</button>
		                        </div>
		                    </div>
		                </div>
		                
		                <div className="col-lg-4 col-md-6 col-sm-9">
		                    <div className="pricing-style-one mt-40 wow fadeIn" data-wow-duration="1.5s" data-wow-delay="0.8s">
		                        <div className="pricing-header text-center">
		                            <h5 className="sub-title">Tarjeta navideña</h5>
		                            <p className="month"><span className="price">$ 50</span></p>
		                        </div>
		                        <div className="pricing-list">
		                            <ul>
		                                <li><i className="lni-check-mark-circle"></i> 3 días publicada</li>
		                                <li><i className="lni-check-mark-circle"></i> Canción de tu elección</li>
		                            </ul>
		                        </div>
		                        <div className="pricing-btn rounded-buttons text-center">
		                            <button type="button" className="main-btn rounded-three" data-toggle="modal" data-target="#modal_tn">COMPRAR</button>
		                        </div>
		                    </div>
		                </div>
		            </div>
		        </div>
		    </section>

		    <div className="modal fade" id="modal_pb" tabIndex="-1" role="dialog" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-lg" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Proceso de compra</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<div id="carousel_pb" className="carousel slide" data-ride="carousel" data-interval="false">
								<ol className="carousel-indicators">
									<li data-target="#carousel_pb" data-slide-to="0" className="active"></li>
									<li data-target="#carousel_pb" data-slide-to="1"></li>
									<li data-target="#carousel_pb" data-slide-to="2"></li>
								</ol>
								<div className="carousel-inner">
									<div className="carousel-item active">
										<picture>
											<source media="(max-width:550px)" srcSet="assets/images/compra/PB/paso_1_min.png" />
											<img className="d-block w-100" src="assets/images/compra/PB/paso_1_max.png" alt="First slide" />
										</picture>
									</div>
									<div className="carousel-item">
										<picture>
											<source media="(max-width:550px)" srcSet="assets/images/compra/PB/paso_2_min.png" />
											<img className="d-block w-100" src="assets/images/compra/PB/paso_2_max.png" alt="Second slide" />
										</picture>
									</div>
									<div className="carousel-item">
										<picture>
											<source media="(max-width:550px)" srcSet="assets/images/compra/PB/paso_3_min.png" />
											<img className="d-block w-100" src="assets/images/compra/PB/paso_3_max.png" alt="Third slide" />
										</picture>
									</div>
								</div>
								<a className="carousel-control-prev" href="#carousel_pb" role="button" data-slide="prev">
									<span className="carousel-control-prev-icon" aria-hidden="true"></span>
									<span className="sr-only">Previous</span>
								</a>
								<a className="carousel-control-next" href="#carousel_pb" role="button" data-slide="next">
									<span className="carousel-control-next-icon" aria-hidden="true"></span>
									<span className="sr-only">Next</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="modal fade" id="modal_pa" tabIndex="-1" role="dialog" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-lg" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Proceso de compra</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<div id="carousel_pa" className="carousel slide" data-ride="carousel" data-interval="false">
								<ol className="carousel-indicators">
									<li data-target="#carousel_pa" data-slide-to="0" className="active"></li>
									<li data-target="#carousel_pa" data-slide-to="1"></li>
									<li data-target="#carousel_pa" data-slide-to="2"></li>
								</ol>
								<div className="carousel-inner">
									<div className="carousel-item active">
										<picture>
											<source media="(max-width:550px)" srcSet="assets/images/compra/PA/paso_1_min.png" />
											<img className="d-block w-100" src="assets/images/compra/PA/paso_1_max.png" alt="First slide" />
										</picture>
									</div>
									<div className="carousel-item">
										<picture>
											<source media="(max-width:550px)" srcSet="assets/images/compra/PA/paso_2_min.png" />
											<img className="d-block w-100" src="assets/images/compra/PA/paso_2_max.png" alt="Second slide" />
										</picture>
									</div>
									<div className="carousel-item">
										<picture>
											<source media="(max-width:550px)" srcSet="assets/images/compra/PA/paso_3_min.png" />
											<img className="d-block w-100" src="assets/images/compra/PA/paso_3_max.png" alt="Third slide" />
										</picture>
									</div>
								</div>
								<a className="carousel-control-prev" href="#carousel_pa" role="button" data-slide="prev">
									<span className="carousel-control-prev-icon" aria-hidden="true"></span>
									<span className="sr-only">Previous</span>
								</a>
								<a className="carousel-control-next" href="#carousel_pa" role="button" data-slide="next">
									<span className="carousel-control-next-icon" aria-hidden="true"></span>
									<span className="sr-only">Next</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="modal fade" id="modal_tf" tabIndex="-1" role="dialog" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-lg" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Proceso de compra</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<div id="carousel_tf" className="carousel slide" data-ride="carousel" data-interval="false">
								<ol className="carousel-indicators">
									<li data-target="#carousel_tf" data-slide-to="0" className="active"></li>
									<li data-target="#carousel_tf" data-slide-to="1"></li>
									<li data-target="#carousel_tf" data-slide-to="2"></li>
								</ol>
								<div className="carousel-inner">
									<div className="carousel-item active">
										<picture>
											<source media="(max-width:550px)" srcSet="assets/images/compra/TF/paso_1_min.png" />
											<img className="d-block w-100" src="assets/images/compra/TF/paso_1_max.png" alt="First slide" />
										</picture>
									</div>
									<div className="carousel-item">
										<picture>
											<source media="(max-width:550px)" srcSet="assets/images/compra/TF/paso_2_min.png" />
											<img className="d-block w-100" src="assets/images/compra/TF/paso_2_max.png" alt="Second slide" />
										</picture>
									</div>
									<div className="carousel-item">
										<picture>
											<source media="(max-width:550px)" srcSet="assets/images/compra/TF/paso_3_min.png" />
											<img className="d-block w-100" src="assets/images/compra/TF/paso_3_max.png" alt="Third slide" />
										</picture>
									</div>
								</div>
								<a className="carousel-control-prev" href="#carousel_tf" role="button" data-slide="prev">
									<span className="carousel-control-prev-icon" aria-hidden="true"></span>
									<span className="sr-only">Previous</span>
								</a>
								<a className="carousel-control-next" href="#carousel_tf" role="button" data-slide="next">
									<span className="carousel-control-next-icon" aria-hidden="true"></span>
									<span className="sr-only">Next</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="modal fade" id="modal_ef" tabIndex="-1" role="dialog" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-lg" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Proceso de compra</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<div id="carousel_ef" className="carousel slide" data-ride="carousel" data-interval="false">
								<ol className="carousel-indicators">
									<li data-target="#carousel_ef" data-slide-to="0" className="active"></li>
									<li data-target="#carousel_ef" data-slide-to="1"></li>
									<li data-target="#carousel_ef" data-slide-to="2"></li>
								</ol>
								<div className="carousel-inner">
									<div className="carousel-item active">
										<picture>
											<source media="(max-width:550px)" srcSet="assets/images/compra/EF/paso_1_min.png" />
											<img className="d-block w-100" src="assets/images/compra/EF/paso_1_max.png" alt="First slide" />
										</picture>
									</div>
									<div className="carousel-item">
										<picture>
											<source media="(max-width:550px)" srcSet="assets/images/compra/EF/paso_2_min.png" />
											<img className="d-block w-100" src="assets/images/compra/EF/paso_2_max.png" alt="Second slide" />
										</picture>
									</div>
									<div className="carousel-item">
										<picture>
											<source media="(max-width:550px)" srcSet="assets/images/compra/EF/paso_3_min.png" />
											<img className="d-block w-100" src="assets/images/compra/EF/paso_3_max.png" alt="Third slide" />
										</picture>
									</div>
								</div>
								<a className="carousel-control-prev" href="#carousel_ef" role="button" data-slide="prev">
									<span className="carousel-control-prev-icon" aria-hidden="true"></span>
									<span className="sr-only">Previous</span>
								</a>
								<a className="carousel-control-next" href="#carousel_ef" role="button" data-slide="next">
									<span className="carousel-control-next-icon" aria-hidden="true"></span>
									<span className="sr-only">Next</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="modal fade" id="modal_tn" tabIndex="-1" role="dialog" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-lg" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Proceso de compra</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<div id="carousel_tn" className="carousel slide" data-ride="carousel" data-interval="false">
								<ol className="carousel-indicators">
									<li data-target="#carousel_tn" data-slide-to="0" className="active"></li>
									<li data-target="#carousel_tn" data-slide-to="1"></li>
									<li data-target="#carousel_tn" data-slide-to="2"></li>
								</ol>
								<div className="carousel-inner">
									<div className="carousel-item active">
										<picture>
											<source media="(max-width:550px)" srcSet="assets/images/compra/TN/paso_1_min.png" />
											<img className="d-block w-100" src="assets/images/compra/TN/paso_1_max.png" alt="First slide" />
										</picture>
									</div>
									<div className="carousel-item">
										<picture>
											<source media="(max-width:550px)" srcSet="assets/images/compra/TN/paso_2_min.png" />
											<img className="d-block w-100" src="assets/images/compra/TN/paso_2_max.png" alt="Second slide" />
										</picture>
									</div>
									<div className="carousel-item">
										<picture>
											<source media="(max-width:550px)" srcSet="assets/images/compra/TN/paso_3_min.png" />
											<img className="d-block w-100" src="assets/images/compra/TN/paso_3_max.png" alt="Third slide" />
										</picture>
									</div>
								</div>
								<a className="carousel-control-prev" href="#carousel_tn" role="button" data-slide="prev">
									<span className="carousel-control-prev-icon" aria-hidden="true"></span>
									<span className="sr-only">Previous</span>
								</a>
								<a className="carousel-control-next" href="#carousel_tn" role="button" data-slide="next">
									<span className="carousel-control-next-icon" aria-hidden="true"></span>
									<span className="sr-only">Next</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>

		    <section id="testimonial" className="testimonial-area">
		        <div className="container">
		            <div className="row justify-content-center">
		                <div className="col-lg-6">
		                    <div className="section-title text-center pb-20">
		                        <h3 className="title">Clientes</h3>
		                        <p className="text">Aquí algunas de las opiniones de quienes ya son clientes:</p>
		                    </div>
		                </div>
		            </div>
		            
		            <div className="row">
		                <div className="col-lg-12">
		                    <div className="row testimonial-active">
		                        <div className="col-lg-4">
		                            <div className="single-testimonial mt-30 mb-30 text-center">
		                                <div className="testimonial-content">
		                                    <p className="text">Hola amigo, los duidetalles son todo un éxito, tuve una buena experiencia ya que quería hacerle una sorpresa a mi novia y vaya que la sorprendí, ella no sabía ni que onda pero tuvo que hacer la encuesta y al finalizar su reacción fue epica, se quedó con las lágrimas brotando y una enorme sonrisa, es muy bueno tu producto y si tienes nuevas ideas estoy considerando en utilizarlas, muchas gracias broooo 🤙🏻</p>
		                                    <h6 className="author-name">Luis Daniel</h6>
		                                    <span className="sub-title">Novio amoroso</span>
		                                </div>
		                            </div>
		                        </div>
		                        <div className="col-lg-4">
		                            <div className="single-testimonial mt-30 mb-30 text-center">
		                                <div className="testimonial-content">
		                                    <p className="text">Los mejores detalles digitales, muy originales, la persona a quien se lo regale, quedo encantada. Lo mejor de todo es que es un detalle que no se esperan y eso lo hace aun mejor, sin duda es un regalo que le gustaría a cualquier persona en cualquier ocasión especial. Muy agradecido con Duitalles 🙌🏽</p>
		                                    <h6 className="author-name">Anul</h6>
		                                    <span className="sub-title">Novio romanticón</span>
		                                </div>
		                            </div>
		                        </div>
		                        <div className="col-lg-4">
		                            <div className="single-testimonial mt-30 mb-30 text-center">
		                                <div className="testimonial-content">
		                                    <p className="text">La verdad DUIDETALLES es un trabajo muy bien hecho formal, es una nueva forma de querer demostrar a la persona que mas amas lo importante que es para ti es una idea muy original y la verdad algo que posiblemente no se pudieran esperar ya que empiezas de la forma que quieras ya se a una encuesta o un video que no sea relacionado a esto en especifico. Muy recomendable y aparte rápido de realizar.</p>
		                                    <h6 className="author-name">Franciso</h6>
		                                    <span className="sub-title">Novio mandilón</span>
		                                </div>
		                            </div>
		                        </div>
		                    </div>
		                </div>
		            </div>
		        </div>
		    </section>

		    <section id="contact" className="contact-area">
		        <div className="container">
		            <div className="row justify-content-center">
		                <div className="col-lg-6">
		                    <div className="section-title text-center pb-20">
		                        <h3 className="title">¿Alguna duda o sugerencia?</h3>
		                        <p className="text">Contáctanos vía Facebook, Instagram o WhatsApp.</p>
		                    </div>
		                </div>
		            </div>
		            <div className="row">
		            	<div className="offset-md-4 col-sm-4 text-center">
                            <a href="https://api.whatsapp.com/send?phone=+524491875817&text=Duitalles" target="_blank" rel="noopener noreferrer" style={{ fontSize: '52px', color: '#9a3030' }}><i className="lni-whatsapp"></i></a>
	                    </div>
		            </div>
		        </div>
		    </section>
	    </Fragment>
    )
}

export default Encuesta;